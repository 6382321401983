var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm._l(_vm.lanesList, function (lane, key) {
        return [
          _c(
            "v-card",
            { key: key },
            [
              _c(
                "v-card-text",
                [
                  key === 0
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "blue--text text--lighten-2 font-size-14",
                              attrs: {
                                cols: "10",
                                sm: "10",
                                md: "10",
                                lg: "10",
                                xl: "10",
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass:
                                    "pl-3 pr-1 blue--text text--lighten-2",
                                  attrs: { medium: "" },
                                },
                                [_vm._v(" mdi-highway ")]
                              ),
                              _vm._v(" Lanes "),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "2" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "font-weight-bold float-right",
                                  staticStyle: { color: "#1e88e5" },
                                  attrs: { small: "", text: "" },
                                  on: { click: _vm.addLane },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass:
                                        "pl-3 pr-1 blue--text text--lighten-2",
                                      attrs: { medium: "" },
                                    },
                                    [_vm._v(" mdi-plus-circle-outline ")]
                                  ),
                                  _vm._v(" Add Lane "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            sm: "3",
                            md: "3",
                            lg: "3",
                            xl: "3",
                          },
                        },
                        [
                          _c("v-autocomplete", {
                            staticClass: "pt-0 pb-0",
                            attrs: {
                              items: lane.originLocationList,
                              color: "info",
                              "search-input": lane.originQuery,
                              clearable: "",
                              "item-text": "title",
                              "item-value": "id",
                              label: "Origin",
                              "return-object": "",
                              required: "",
                              "close-on-content-click": "",
                              "item-width": "200",
                              filter: (v) => v,
                            },
                            on: {
                              "update:searchInput": function ($event) {
                                return _vm.$set(lane, "originQuery", $event)
                              },
                              "update:search-input": [
                                function ($event) {
                                  return _vm.$set(lane, "originQuery", $event)
                                },
                                function ($event) {
                                  return _vm.originQuerySearch(
                                    lane.originQuery,
                                    key
                                  )
                                },
                              ],
                              change: function ($event) {
                                return _vm.setOriginPlace(key)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function (data) {
                                    return [
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  data.item.title
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: lane.srcLocation.currentPlace,
                              callback: function ($$v) {
                                _vm.$set(lane.srcLocation, "currentPlace", $$v)
                              },
                              expression: "lane.srcLocation.currentPlace",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            sm: "3",
                            md: "3",
                            lg: "3",
                            xl: "3",
                          },
                        },
                        [
                          _c("v-autocomplete", {
                            staticClass: "pt-0 pb-0",
                            attrs: {
                              items: lane.destinationLocationList,
                              "search-input": lane.destinationQuery,
                              color: "info",
                              clearable: "",
                              "item-text": "title",
                              "item-value": "id",
                              label: "Destination",
                              "return-object": "",
                              required: "",
                              "close-on-content-click": "",
                              "item-width": "200",
                              filter: (v) => v,
                            },
                            on: {
                              "update:searchInput": function ($event) {
                                return _vm.$set(
                                  lane,
                                  "destinationQuery",
                                  $event
                                )
                              },
                              "update:search-input": [
                                function ($event) {
                                  return _vm.$set(
                                    lane,
                                    "destinationQuery",
                                    $event
                                  )
                                },
                                function ($event) {
                                  return _vm.destinationQuerySearch(
                                    lane.destinationQuery,
                                    key
                                  )
                                },
                              ],
                              change: function ($event) {
                                return _vm.setDestinationPlace(key)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function (data) {
                                    return [
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  data.item.title
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: lane.dstLocation.currentPlace,
                              callback: function ($$v) {
                                _vm.$set(lane.dstLocation, "currentPlace", $$v)
                              },
                              expression: "lane.dstLocation.currentPlace",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            sm: "2",
                            md: "2",
                            lg: "2",
                            xl: "2",
                          },
                        },
                        [
                          _c("v-select", {
                            staticClass: "mt-2 px-4",
                            staticStyle: { "text-transform": "none" },
                            attrs: {
                              items: _vm.equipmentTypes,
                              label: "Equipment Types",
                              "item-text": "name",
                              "item-value": "id",
                              dense: "",
                              "return-object": "",
                            },
                            model: {
                              value: lane.equipmentType,
                              callback: function ($$v) {
                                _vm.$set(lane, "equipmentType", $$v)
                              },
                              expression: "lane.equipmentType",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            sm: "1",
                            md: "1",
                            lg: "1",
                            xl: "1",
                          },
                        },
                        [
                          _c("v-text-field", {
                            staticClass: "pt-0 px-4",
                            attrs: {
                              disabled: "",
                              type: "text",
                              label: "Miles",
                            },
                            model: {
                              value: lane.miles,
                              callback: function ($$v) {
                                _vm.$set(lane, "miles", $$v)
                              },
                              expression: "lane.miles",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            sm: "1",
                            md: "1",
                            lg: "1",
                            xl: "1",
                          },
                        },
                        [
                          _c("v-text-field", {
                            staticClass: "pt-0 px-4",
                            attrs: { type: "text", label: "Price" },
                            model: {
                              value: lane.carrierBid,
                              callback: function ($$v) {
                                _vm.$set(lane, "carrierBid", $$v)
                              },
                              expression: "lane.carrierBid",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            sm: "2",
                            md: "2",
                            lg: "2",
                            xl: "2",
                          },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "red--text text--lighten-2 font-weight-bold float-right mt-4",
                              attrs: { text: "", small: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeLane(key)
                                },
                              },
                            },
                            [_vm._v(" Remove ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "font-weight-bold float-right mt-4",
                              staticStyle: { color: "#1e88e5" },
                              attrs: { text: "", small: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.saveSingleLane(key)
                                },
                              },
                            },
                            [_vm._v(" Save ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-text", [_vm._v(" Please confirm lane deletion ")]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "grey darken-1", text: "", small: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "orange darken-1", text: "", small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.removeRecord(_vm.index)
                        },
                      },
                    },
                    [_vm._v(" Delete ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }