var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pt-1", attrs: { fluid: "" } },
    [
      _vm.profile
        ? _c(
            "v-row",
            [
              _vm.hasAccess && _vm.profile.approved === "No"
                ? _c(
                    "v-col",
                    {
                      staticClass: "pt-0 pb-0 offset-md-8",
                      attrs: { cols: "12", md: "2" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "float-right",
                          attrs: { color: "green", height: "50" },
                          on: {
                            click: function ($event) {
                              return _vm.approveCarrier()
                            },
                          },
                        },
                        [_vm._v(" Approve Carrier ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "col-md-12 pt-0" },
                [
                  _c(
                    "v-expansion-panels",
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-md-12 blue--text text--lighten-2 font-size-14",
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass:
                                      "pl-3 pr-1 blue--text text--lighten-2",
                                    attrs: { medium: "" },
                                  },
                                  [_vm._v(" mdi-account-circle ")]
                                ),
                                _vm._v(" Profile "),
                              ],
                              1
                            ),
                          ]),
                          _vm.profile !== null
                            ? _c(
                                "v-expansion-panel-content",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _vm.$vuetify.breakpoint.mdAndUp
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0 pb-0 mt-n2",
                                              attrs: { cols: "10" },
                                            },
                                            [_vm._v("   ")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0 pb-0 mt-n2",
                                          attrs: { cols: "12", md: "2" },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "float-right",
                                              attrs: {
                                                color: "info",
                                                text: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.updateProfile()
                                                },
                                              },
                                            },
                                            [_vm._v(" Save ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-form",
                                    {
                                      ref: "profile-form",
                                      model: {
                                        value: _vm.valid,
                                        callback: function ($$v) {
                                          _vm.valid = $$v
                                        },
                                        expression: "valid",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0 pb-0",
                                              attrs: {
                                                cols: "12",
                                                lg: "3",
                                                md: "3",
                                                sm: "12",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  disabled: !_vm.hasAccess,
                                                  rules: [
                                                    _vm.rules.required,
                                                    _vm.rules.alphaNumeric,
                                                  ],
                                                  label: "Name",
                                                  type: "text",
                                                },
                                                model: {
                                                  value: _vm.profile.name,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.profile,
                                                      "name",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "profile.name",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0 pb-0",
                                              attrs: {
                                                cols: "12",
                                                lg: "3",
                                                md: "3",
                                                sm: "12",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  counter: 9,
                                                  disabled: !_vm.hasAccess,
                                                  rules: [
                                                    _vm.rules.required,
                                                    _vm.rules.intDigit,
                                                  ],
                                                  label: "DOT",
                                                  type: "text",
                                                },
                                                model: {
                                                  value: _vm.profile.dotNumber,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.profile,
                                                      "dotNumber",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "profile.dotNumber",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0 pb-0",
                                              attrs: {
                                                cols: "12",
                                                lg: "2",
                                                md: "2",
                                                sm: "12",
                                                xl: "2",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  counter: 10,
                                                  disabled: !_vm.hasAccess,
                                                  rules: [_vm.rules.intDigit],
                                                  label: "MC",
                                                  type: "text",
                                                },
                                                model: {
                                                  value: _vm.profile.mcNumber,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.profile,
                                                      "mcNumber",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "profile.mcNumber",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0 pb-0",
                                              attrs: {
                                                cols: "12",
                                                lg: "2",
                                                md: "2",
                                                sm: "12",
                                                xl: "2",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  disabled: !_vm.hasAccess,
                                                  rules: [
                                                    _vm.rules.alphaNumeric(),
                                                  ],
                                                  label: "SCAC",
                                                  type: "text",
                                                },
                                                model: {
                                                  value: _vm.profile.scacNumber,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.profile,
                                                      "scacNumber",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "profile.scacNumber",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0 pb-0",
                                              attrs: {
                                                cols: "12",
                                                lg: "2",
                                                md: "2",
                                                sm: "12",
                                                xl: "2",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  disabled: !_vm.hasAccess,
                                                  rules: [
                                                    _vm.rules.required,
                                                    _vm.rules.intDigit,
                                                  ],
                                                  label: "EIN",
                                                  type: "text",
                                                },
                                                model: {
                                                  value: _vm.profile.ein,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.profile,
                                                      "ein",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "profile.ein",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0 pb-0",
                                              attrs: {
                                                cols: "12",
                                                lg: "3",
                                                md: "3",
                                                sm: "12",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  rules: [_vm.rules.email],
                                                  label: "Email",
                                                  type: "text",
                                                },
                                                model: {
                                                  value: _vm.profile.email,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.profile,
                                                      "email",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "profile.email",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0 pb-0",
                                              attrs: {
                                                cols: "12",
                                                lg: "3",
                                                md: "3",
                                                sm: "12",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  rules: [
                                                    _vm.rules.required,
                                                    _vm.rules.phone,
                                                  ],
                                                  label: "Phone 1",
                                                  type: "text",
                                                },
                                                model: {
                                                  value: _vm.profile.phone,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.profile,
                                                      "phone",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "profile.phone",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0 pb-0",
                                              attrs: {
                                                cols: "12",
                                                lg: "2",
                                                md: "2",
                                                sm: "12",
                                                xl: "2",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  rules: [_vm.rules.phone],
                                                  label: "Phone 2",
                                                  type: "text",
                                                },
                                                model: {
                                                  value: _vm.profile.phoneTwo,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.profile,
                                                      "phoneTwo",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "profile.phoneTwo",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0 pb-0",
                                              attrs: {
                                                cols: "12",
                                                lg: "2",
                                                md: "2",
                                                sm: "12",
                                                xl: "2",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  rules: [_vm.rules.phone],
                                                  label: "Phone 3",
                                                  type: "text",
                                                },
                                                model: {
                                                  value: _vm.profile.phoneThree,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.profile,
                                                      "phoneThree",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "profile.phoneThree",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0 pb-0",
                                              attrs: {
                                                cols: "12",
                                                lg: "2",
                                                md: "2",
                                                sm: "12",
                                                xl: "2",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  rules: [_vm.rules.phone],
                                                  label: "Fax",
                                                  type: "text",
                                                },
                                                model: {
                                                  value: _vm.profile.fax,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.profile,
                                                      "fax",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "profile.fax",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0 pb-0",
                                              attrs: {
                                                cols: "3",
                                                lg: "3",
                                                md: "3",
                                                sm: "3",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c("v-checkbox", {
                                                attrs: { label: "Send Offers" },
                                                model: {
                                                  value: _vm.profile.sendOffers,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.profile,
                                                      "sendOffers",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "profile.sendOffers",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0 pb-0",
                                              attrs: {
                                                cols: "12",
                                                lg: "3",
                                                md: "3",
                                                sm: "12",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  rules: [_vm.rules.intDigit],
                                                  label: "Days Between Emails",
                                                  type: "text",
                                                },
                                                model: {
                                                  value:
                                                    _vm.profile
                                                      .daysBetweenEmails,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.profile,
                                                      "daysBetweenEmails",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "profile.daysBetweenEmails",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0 pb-0",
                                              attrs: {
                                                cols: "12",
                                                lg: "3",
                                                md: "3",
                                                sm: "12",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c(
                                                "carrier-sales-rep-auto-complete",
                                                {
                                                  attrs: {
                                                    "data-cy":
                                                      "carrier-manager",
                                                    "default-carrier-sales-rep":
                                                      _vm.profile
                                                        .carrierManager,
                                                  },
                                                  on: {
                                                    event:
                                                      _vm.selectedCarrierManager,
                                                  },
                                                }
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-md-12 blue--text text--lighten-2 font-size-14",
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass:
                                      "pl-3 pr-1 blue--text text--lighten-2",
                                    attrs: { medium: "" },
                                  },
                                  [_vm._v(" mdi-account ")]
                                ),
                                _vm._v(" Account Receivable "),
                              ],
                              1
                            ),
                          ]),
                          _vm.profile !== null
                            ? _c(
                                "v-expansion-panel-content",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-2 pb-0",
                                          attrs: {
                                            cols: "6",
                                            lg: "7",
                                            md: "4",
                                          },
                                        },
                                        [
                                          _c("v-checkbox", {
                                            staticClass: "mt-1",
                                            attrs: { label: "Quick Pay" },
                                            model: {
                                              value: _vm.profile.quickPay,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.profile,
                                                  "quickPay",
                                                  $$v
                                                )
                                              },
                                              expression: "profile.quickPay",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-2 pb-0",
                                          attrs: { cols: "6", md: "2" },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "float-right",
                                              attrs: {
                                                color: "info",
                                                text: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.updateARDetail()
                                                },
                                              },
                                            },
                                            [_vm._v(" Save ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-form",
                                    {
                                      ref: "carrier-ar-form",
                                      model: {
                                        value: _vm.arValid,
                                        callback: function ($$v) {
                                          _vm.arValid = $$v
                                        },
                                        expression: "arValid",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0 pb-0",
                                              attrs: {
                                                cols: "12",
                                                lg: "3",
                                                md: "3",
                                                sm: "12",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  rules: [
                                                    _vm.rules.required,
                                                    _vm.rules.alphaNumeric,
                                                  ],
                                                  label: "Name",
                                                  type: "text",
                                                },
                                                model: {
                                                  value: _vm.profile.arName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.profile,
                                                      "arName",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "profile.arName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0 pb-0",
                                              attrs: {
                                                cols: "12",
                                                lg: "3",
                                                md: "3",
                                                sm: "12",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c(
                                                "detail-location-auto-complete",
                                                {
                                                  attrs: {
                                                    address:
                                                      _vm.profile.fullARAddress,
                                                    "country-code":
                                                      _vm.countryCodes,
                                                  },
                                                  on: {
                                                    event: _vm.setARLocation,
                                                  },
                                                }
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0 pb-0",
                                              attrs: {
                                                cols: "12",
                                                lg: "3",
                                                md: "3",
                                                sm: "12",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  rules: [
                                                    _vm.rules.required,
                                                    _vm.rules.phone,
                                                  ],
                                                  label: "Phone",
                                                  type: "text",
                                                },
                                                model: {
                                                  value: _vm.profile.arPhone,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.profile,
                                                      "arPhone",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "profile.arPhone",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0 pb-0",
                                              attrs: {
                                                cols: "12",
                                                lg: "3",
                                                md: "3",
                                                sm: "12",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  rules: [
                                                    _vm.rules.required,
                                                    _vm.rules.email,
                                                  ],
                                                  label: "Email",
                                                  type: "text",
                                                },
                                                model: {
                                                  value: _vm.profile.arEmail,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.profile,
                                                      "arEmail",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "profile.arEmail",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0 pb-0",
                                              attrs: {
                                                cols: "12",
                                                lg: "3",
                                                md: "3",
                                                sm: "12",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  rules: [_vm.rules.email],
                                                  label: "Remit Email",
                                                  type: "text",
                                                },
                                                model: {
                                                  value:
                                                    _vm.profile.arRemitEmail,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.profile,
                                                      "arRemitEmail",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "profile.arRemitEmail",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0 pb-0",
                                              attrs: {
                                                cols: "12",
                                                lg: "3",
                                                md: "3",
                                                sm: "12",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  rules: [
                                                    _vm.rules.alphaNumeric,
                                                  ],
                                                  label: "Contact Name",
                                                  type: "text",
                                                },
                                                model: {
                                                  value:
                                                    _vm.profile.arContactName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.profile,
                                                      "arContactName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "profile.arContactName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0 pb-0",
                                              attrs: {
                                                cols: "12",
                                                lg: "3",
                                                md: "3",
                                                sm: "12",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  rules: [_vm.rules.intDigit],
                                                  label: "Pay Terms",
                                                  type: "text",
                                                },
                                                model: {
                                                  value: _vm.profile.payTerms,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.profile,
                                                      "payTerms",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "profile.payTerms",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0 pb-0",
                                              attrs: {
                                                cols: "12",
                                                lg: "3",
                                                md: "3",
                                                sm: "12",
                                                xl: "3",
                                              },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  rules: [_vm.rules.intDigit],
                                                  label: "Quickbooks ID",
                                                  type: "text",
                                                },
                                                model: {
                                                  value:
                                                    _vm.profile.quickbooksId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.profile,
                                                      "quickbooksId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "profile.quickbooksId",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-md-12 blue--text text--lighten-2 font-size-14",
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass:
                                      "pl-3 pr-1 blue--text text--lighten-2",
                                    attrs: { medium: "" },
                                  },
                                  [_vm._v(" mdi-map-marker ")]
                                ),
                                _vm._v(" Address "),
                              ],
                              1
                            ),
                          ]),
                          _vm.profile !== null
                            ? _c(
                                "v-expansion-panel-content",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _vm.$vuetify.breakpoint.mdAndUp
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0 pb-0 mt-n2",
                                              attrs: { cols: "10" },
                                            },
                                            [_vm._v("   ")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0 pb-0 mt-n2",
                                          attrs: { cols: "12", md: "2" },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "float-right",
                                              attrs: {
                                                color: "info",
                                                text: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.updateAddressDetail()
                                                },
                                              },
                                            },
                                            [_vm._v(" Save ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-form",
                                    {
                                      ref: "carrier-address-form",
                                      model: {
                                        value: _vm.addressValid,
                                        callback: function ($$v) {
                                          _vm.addressValid = $$v
                                        },
                                        expression: "addressValid",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0 pb-0 pl-12",
                                              attrs: {
                                                cols: "12",
                                                lg: "4",
                                                md: "4",
                                                sm: "12",
                                                xl: "4",
                                              },
                                            },
                                            [
                                              _c(
                                                "detail-location-auto-complete",
                                                {
                                                  attrs: {
                                                    address:
                                                      _vm.profile.fullAddress,
                                                    "country-code":
                                                      _vm.countryCodes,
                                                    disabled: !_vm.hasAccess,
                                                  },
                                                  on: {
                                                    event: _vm.setLocation,
                                                  },
                                                }
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-md-9 blue--text text--lighten-2 font-size-14",
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass:
                                      "pl-3 pr-1 blue--text text--lighten-2",
                                    attrs: { medium: "" },
                                  },
                                  [_vm._v(" mdi-account-box-outline ")]
                                ),
                                _vm._v(" Contact "),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-sm-10 col-md-6 col-lg-6 col-xl-6",
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "font-weight-bold float-right",
                                    staticStyle: { color: "#1e88e5" },
                                    attrs: { small: "", text: "" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.addContact.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "pl-3 pr-1",
                                        attrs: { medium: "" },
                                      },
                                      [_vm._v(" mdi-plus-circle-outline ")]
                                    ),
                                    _vm.$vuetify.breakpoint.mdAndUp
                                      ? _c("span", [_vm._v("Add Contact")])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "font-weight-bold float-right",
                                    staticStyle: { color: "#1e88e5" },
                                    attrs: { small: "", text: "" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.saveContact.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "pl-3 pr-1",
                                        attrs: { medium: "" },
                                      },
                                      [_vm._v(" mdi-content-save-all ")]
                                    ),
                                    _vm.$vuetify.breakpoint.mdAndUp
                                      ? _c("span", [_vm._v("Save All")])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._l(_vm.contacts, function (item, key) {
                            return [
                              _c(
                                "v-expansion-panel-content",
                                { key: key, attrs: { fluid: "" } },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "px-4" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0 pb-0",
                                          attrs: {
                                            cols: "12",
                                            lg: "4",
                                            md: "4",
                                            sm: "12",
                                            xl: "4",
                                          },
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Name",
                                              type: "text",
                                            },
                                            model: {
                                              value: item.name,
                                              callback: function ($$v) {
                                                _vm.$set(item, "name", $$v)
                                              },
                                              expression: "item.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0 pb-0",
                                          attrs: {
                                            cols: "12",
                                            lg: "4",
                                            md: "4",
                                            sm: "12",
                                            xl: "4",
                                          },
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Phone",
                                              type: "text",
                                            },
                                            model: {
                                              value: item.phone,
                                              callback: function ($$v) {
                                                _vm.$set(item, "phone", $$v)
                                              },
                                              expression: "item.phone",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0 pb-0",
                                          attrs: {
                                            cols: "12",
                                            lg: "4",
                                            md: "4",
                                            sm: "12",
                                            xl: "4",
                                          },
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Email",
                                              type: "text",
                                            },
                                            model: {
                                              value: item.email,
                                              callback: function ($$v) {
                                                _vm.$set(item, "email", $$v)
                                              },
                                              expression: "item.email",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0 pb-0",
                                          attrs: {
                                            cols: "3",
                                            lg: "2",
                                            md: "2",
                                            sm: "3",
                                            xl: "2",
                                          },
                                        },
                                        [
                                          _c("v-checkbox", {
                                            attrs: { label: "Primary" },
                                            model: {
                                              value: item.isPrimary,
                                              callback: function ($$v) {
                                                _vm.$set(item, "isPrimary", $$v)
                                              },
                                              expression: "item.isPrimary",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0 pb-0",
                                          attrs: {
                                            cols: "3",
                                            lg: "2",
                                            md: "2",
                                            sm: "3",
                                            xl: "2",
                                          },
                                        },
                                        [
                                          _c("v-checkbox", {
                                            attrs: { label: "Send Offer" },
                                            model: {
                                              value: item.sendOffers,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "sendOffers",
                                                  $$v
                                                )
                                              },
                                              expression: "item.sendOffers",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0 pb-0",
                                          attrs: {
                                            cols: "3",
                                            lg: "2",
                                            md: "2",
                                            sm: "3",
                                            xl: "2",
                                          },
                                        },
                                        [
                                          _c("v-checkbox", {
                                            attrs: { label: "Dispatch" },
                                            model: {
                                              value: item.isDispatch,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "isDispatch",
                                                  $$v
                                                )
                                              },
                                              expression: "item.isDispatch",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0 pb-0",
                                          attrs: {
                                            cols: "3",
                                            lg: "2",
                                            md: "2",
                                            sm: "3",
                                            xl: "2",
                                          },
                                        },
                                        [
                                          _c("v-checkbox", {
                                            attrs: { label: "Accounting" },
                                            model: {
                                              value: item.isAccounting,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "isAccounting",
                                                  $$v
                                                )
                                              },
                                              expression: "item.isAccounting",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0 pb-0",
                                          attrs: {
                                            cols: "3",
                                            lg: "2",
                                            md: "2",
                                            sm: "3",
                                            xl: "2",
                                          },
                                        },
                                        [
                                          _c("v-checkbox", {
                                            attrs: { label: "Claims" },
                                            model: {
                                              value: item.isClaims,
                                              callback: function ($$v) {
                                                _vm.$set(item, "isClaims", $$v)
                                              },
                                              expression: "item.isClaims",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0 pb-0",
                                          attrs: {
                                            cols: "3",
                                            lg: "2",
                                            md: "2",
                                            sm: "3",
                                            xl: "2",
                                          },
                                        },
                                        [
                                          _c("v-checkbox", {
                                            attrs: { label: "Driver" },
                                            model: {
                                              value: item.isDriver,
                                              callback: function ($$v) {
                                                _vm.$set(item, "isDriver", $$v)
                                              },
                                              expression: "item.isDriver",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-right",
                                          attrs: {
                                            cols: "4",
                                            lg: "4",
                                            sm: "4",
                                            xl: "4",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "blue--text text--lighten-2 font-weight-bold mt-4",
                                              attrs: { small: "", text: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.saveSingleContact(
                                                    key
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" Save ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "red--text white--text font-weight-bold mt-4",
                                              attrs: { small: "", text: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeContact(key)
                                                },
                                              },
                                            },
                                            [_vm._v(" Remove ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.contacts.length > 1
                                    ? _c("v-divider")
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-md-9 blue--text text--lighten-2 font-size-14",
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass:
                                      "pl-3 pr-1 blue--text text--lighten-2",
                                    attrs: { medium: "" },
                                  },
                                  [_vm._v(" mdi-hazard-lights ")]
                                ),
                                _vm._v(" Insurance "),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-sm-10 col-md-6 col-lg-6 col-xl-6",
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "font-weight-bold float-right",
                                    staticStyle: { color: "#1e88e5" },
                                    attrs: { small: "", text: "" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.addInsurance.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "pl-3 pr-1",
                                        attrs: { medium: "" },
                                      },
                                      [_vm._v(" mdi-plus-circle-outline ")]
                                    ),
                                    _vm.$vuetify.breakpoint.mdAndUp
                                      ? _c("span", [_vm._v("Add Insurance")])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "font-weight-bold float-right",
                                    staticStyle: { color: "#1e88e5" },
                                    attrs: { small: "", text: "" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.saveInsurance.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "pl-3 pr-1",
                                        attrs: { medium: "" },
                                      },
                                      [_vm._v(" mdi-content-save-all ")]
                                    ),
                                    _vm.$vuetify.breakpoint.mdAndUp
                                      ? _c("span", [_vm._v("Save All")])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._l(_vm.insurances, function (insurance, key) {
                            return [
                              _c(
                                "v-expansion-panel-content",
                                { key: key, attrs: { fluid: "" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0 pb-0",
                                          attrs: {
                                            cols: "12",
                                            lg: "2",
                                            md: "2",
                                            sm: "12",
                                            xl: "2",
                                          },
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              disabled: !_vm.hasAccess,
                                              items: _vm.insuranceType,
                                              "item-text": "name",
                                              "item-value": "id",
                                              label: "Insurance Type",
                                            },
                                            model: {
                                              value: insurance.insuranceType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  insurance,
                                                  "insuranceType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "insurance.insuranceType",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0 pb-0",
                                          attrs: {
                                            cols: "12",
                                            lg: "2",
                                            md: "2",
                                            sm: "12",
                                            xl: "2",
                                          },
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              disabled: !_vm.hasAccess,
                                              label: "Amount",
                                              type: "text",
                                            },
                                            model: {
                                              value: insurance.coverageAmount,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  insurance,
                                                  "coverageAmount",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "insurance.coverageAmount",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0 pb-0",
                                          attrs: {
                                            cols: "12",
                                            lg: "2",
                                            md: "2",
                                            sm: "12",
                                            xl: "2",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              attrs: {
                                                "close-on-content-click": false,
                                                disabled: !_vm.hasAccess,
                                                "offset-y": "",
                                                transition: "scale-transition",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-text-field",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm.hasAccess,
                                                                  label:
                                                                    "Expiration",
                                                                  "persistent-hint":
                                                                    "",
                                                                  "prepend-icon":
                                                                    "mdi-calendar",
                                                                  readonly: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    insurance.expirationDate,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        insurance,
                                                                        "expirationDate",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "insurance.expirationDate",
                                                                },
                                                              },
                                                              "v-text-field",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          )
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value:
                                                  insurance.expirationDateMenu,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    insurance,
                                                    "expirationDateMenu",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "insurance.expirationDateMenu",
                                              },
                                            },
                                            [
                                              _c("v-date-picker", {
                                                attrs: { "no-title": "" },
                                                on: {
                                                  input: function ($event) {
                                                    insurance.expirationDateMenu = false
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    insurance.expirationDate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      insurance,
                                                      "expirationDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "insurance.expirationDate",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0 pb-0",
                                          attrs: {
                                            cols: "12",
                                            lg: "2",
                                            md: "2",
                                            sm: "12",
                                            xl: "2",
                                          },
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              disabled: !_vm.hasAccess,
                                              label: "Provider",
                                              type: "text",
                                            },
                                            model: {
                                              value: insurance.provider,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  insurance,
                                                  "provider",
                                                  $$v
                                                )
                                              },
                                              expression: "insurance.provider",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0 pb-0",
                                          attrs: {
                                            cols: "12",
                                            lg: "2",
                                            md: "2",
                                            sm: "12",
                                            xl: "2",
                                          },
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              disabled: !_vm.hasAccess,
                                              label: "Producer",
                                              type: "text",
                                            },
                                            model: {
                                              value: insurance.producer,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  insurance,
                                                  "producer",
                                                  $$v
                                                )
                                              },
                                              expression: "insurance.producer",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0 pb-0",
                                          attrs: {
                                            cols: "12",
                                            lg: "2",
                                            md: "2",
                                            sm: "12",
                                            xl: "2",
                                          },
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              disabled: !_vm.hasAccess,
                                              label: "Policy Number",
                                              type: "text",
                                            },
                                            model: {
                                              value: insurance.policyNumber,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  insurance,
                                                  "policyNumber",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "insurance.policyNumber",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            attrs: {
                                              disabled: !_vm.hasAccess,
                                              label: "Notes",
                                              type: "text",
                                            },
                                            model: {
                                              value: insurance.notes,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  insurance,
                                                  "notes",
                                                  $$v
                                                )
                                              },
                                              expression: "insurance.notes",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-right",
                                          attrs: {
                                            cols: "12",
                                            lg: "2",
                                            md: "2",
                                            sm: "2",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "font-weight-bold mt-4",
                                              staticStyle: { color: "#1e88e5" },
                                              attrs: { small: "", text: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.saveSingleInsurance(
                                                    key
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" Save ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "red--text white--text font-weight-bold mt-4 ml-2",
                                              attrs: { small: "", text: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeInsurance(
                                                    key
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" Remove ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.insurances.length > 1
                                    ? _c("v-divider")
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "290" },
                  model: {
                    value: _vm.dialog,
                    callback: function ($$v) {
                      _vm.dialog = $$v
                    },
                    expression: "dialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-text", [
                        _vm._v(
                          " Please confirm " +
                            _vm._s(_vm.removeItem) +
                            " deletion "
                        ),
                      ]),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "grey darken-1",
                                small: "",
                                text: "",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.dialog = false
                                },
                              },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "orange darken-1",
                                small: "",
                                text: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.removeRecord(_vm.index)
                                },
                              },
                            },
                            [_vm._v(" Delete " + _vm._s(_vm.removeItem) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }