var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pt-1", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pt-0" },
            [
              _c(
                "v-container",
                {
                  staticClass: "pl-0 pr-0 pt-0",
                  staticStyle: {
                    "overflow-y": "auto",
                    "max-height": "550px",
                    position: "relative",
                  },
                  attrs: { id: "tblShipments", fluid: "" },
                },
                [
                  _c("v-data-table", {
                    staticClass: "elevation-2 data-table",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.shipments,
                      "items-per-page": _vm.pageSize,
                      "calculate-widths": "",
                      "fixed-header": "",
                      "hide-default-footer": "",
                    },
                    on: { "click:row": _vm.showMatchedLoad },
                    scopedSlots: _vm._u([
                      {
                        key: "item.id",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  to: {
                                    name: "Load Detail",
                                    params: { id: item.id },
                                  },
                                  small: "",
                                  target: "_blank",
                                  text: "",
                                },
                              },
                              [_vm._v(" " + _vm._s(item.id) + " ")]
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.shipper",
                        fn: function ({ item }) {
                          return [
                            item.shipper !== null
                              ? _c("p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatLocation(
                                          item.shipper.city,
                                          item.shipper.state_province_region
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c("br"),
                                  _c("span", { staticClass: "subtitle" }, [
                                    _vm._v(_vm._s(item.pickupDate)),
                                  ]),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "item.consignee",
                        fn: function ({ item }) {
                          return [
                            item.consignee !== null
                              ? _c("p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatLocation(
                                          item.consignee.city,
                                          item.consignee.state_province_region
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c("br"),
                                  _c("span", { staticClass: "subtitle" }, [
                                    _vm._v(_vm._s(item.deliveryDate)),
                                  ]),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "item.loadStatus",
                        fn: function ({ item }) {
                          return [
                            _c("load-status-button", {
                              attrs: {
                                "agent-detail": _vm.agentDetail,
                                "disable-click": true,
                                "load-status-items": _vm.loadStatusChoices,
                                "shipment-detail": item,
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("v-page", {
                    staticClass: "pt-3 pr-3",
                    attrs: {
                      "page-size-menu": [10, 20, 50, 100],
                      "total-row": _vm.totalRecord,
                    },
                    on: { "page-change": _vm.readDataFromAPI },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("related-loads", { attrs: { "carrier-id": _vm.carrierId } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }