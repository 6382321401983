var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "matches-detail" },
    [
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            fullscreen: _vm.$vuetify.breakpoint.smAndDown ? false : true,
            transition: "slide-x-reverse-transition",
            "content-class": "match-detail-dialog",
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-toolbar",
            {
              staticStyle: { "background-color": "#1e88e5" },
              attrs: { dark: "" },
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")]), _c("p")],
                1
              ),
              _vm.dialogLoad
                ? _c("v-toolbar-title", [
                    _c("b", [
                      _vm._v(
                        _vm._s(_vm.dialogLoad.customerCompany) +
                          " | " +
                          _vm._s(_vm.loadId)
                      ),
                    ]),
                    _vm._v(" - " + _vm._s(_vm.dialogLoad.equipmentType) + " "),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm.dialogLoad
            ? _c(
                "v-container",
                { staticClass: "pb-0", attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    { staticStyle: { "background-color": "#ffffff" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "text-h5 mb-1" },
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: "green", "x-large": "" } },
                                [_vm._v(" mdi-map-marker ")]
                              ),
                              _vm._v(" Origin "),
                            ],
                            1
                          ),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.dialogLoad.shipper.city) +
                                ", " +
                                _vm._s(
                                  _vm.dialogLoad.shipper.state_province_region
                                ) +
                                " | " +
                                _vm._s(
                                  _vm.formatDate(_vm.dialogLoad.pickupDate)
                                ) +
                                " "
                            ),
                          ]),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.dialogLoad.pickupEarlyTime) +
                                " - " +
                                _vm._s(_vm.dialogLoad.pickupLateTime) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "text-h5 mb-1" },
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: "red", "x-large": "" } },
                                [_vm._v(" mdi-map-marker ")]
                              ),
                              _vm._v(" Destination "),
                            ],
                            1
                          ),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.dialogLoad.consignee.city) +
                                ", " +
                                _vm._s(
                                  _vm.dialogLoad.consignee.state_province_region
                                ) +
                                " | " +
                                _vm._s(
                                  _vm.formatDate(_vm.dialogLoad.deliveryDate)
                                ) +
                                " "
                            ),
                          ]),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.dialogLoad.deliveryEarlyTime) +
                                " - " +
                                _vm._s(_vm.dialogLoad.deliveryLateTime) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticStyle: { "background-color": "#ffffff" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { cols: "4", md: "2" } },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "text-h4 mb-1" },
                            [_c("b", [_vm._v("Agent")])]
                          ),
                          _c("p", [_vm._v(_vm._s(_vm.dialogLoad.agentName))]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { cols: "4", md: "2" } },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "text-h4 mb-1" },
                            [_c("b", [_vm._v("Load Status")])]
                          ),
                          _c("p", [_vm._v(_vm._s(_vm.dialogLoad.loadStatus))]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { cols: "4", md: "2" } },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "text-h4 mb-1" },
                            [_c("b", [_vm._v("Customer Ref.")])]
                          ),
                          _c("p", [
                            _vm._v(_vm._s(_vm.dialogLoad.customerReferenceId)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { cols: "4", md: "2" } },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "text-h4 mb-1" },
                            [_c("b", [_vm._v("Carrier Buy")])]
                          ),
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.dialogLoad.carrierStartBuy) +
                                " - " +
                                _vm._s(_vm.dialogLoad.carrierMaxBuy) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { cols: "4", md: "2" } },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "text-h4 mb-1" },
                            [_c("b", [_vm._v("Customer Pay")])]
                          ),
                          _c("p", [
                            _vm._v(_vm._s(_vm.dialogLoad.customerPrice)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { cols: "4", md: "2" } },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "text-h4 mb-1" },
                            [_c("b", [_vm._v("Miles")])]
                          ),
                          _c("p", [_vm._v(_vm._s(_vm.dialogLoad.miles))]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticStyle: { "background-color": "#ffffff" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-0", attrs: { cols: "4", md: "2" } },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "text-h4 mb-1" },
                            [_c("b", [_vm._v("Height")])]
                          ),
                          _c("p", [_vm._v(_vm._s(_vm.dialogLoad.loadHeight))]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pt-0", attrs: { cols: "4", md: "2" } },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "text-h4 mb-1" },
                            [_c("b", [_vm._v("Weight")])]
                          ),
                          _c("p", [_vm._v(_vm._s(_vm.dialogLoad.loadWeight))]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pt-0", attrs: { cols: "4", md: "2" } },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "text-h4 mb-1" },
                            [_c("b", [_vm._v("Length")])]
                          ),
                          _c("p", [_vm._v(_vm._s(_vm.dialogLoad.loadLength))]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pt-0", attrs: { cols: "4", md: "2" } },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "text-h4 mb-1" },
                            [_c("b", [_vm._v("Width")])]
                          ),
                          _c("p", [_vm._v(_vm._s(_vm.dialogLoad.loadWidth))]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pt-0", attrs: { cols: "8", md: "4" } },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "text-h4 mb-1" },
                            [_c("b", [_vm._v("Commodity")])]
                          ),
                          _c("p", [_vm._v(_vm._s(_vm.dialogLoad.commodity))]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticStyle: {
                        "border-top": "solid 1px #ddd",
                        height: "100%",
                      },
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-expansion-panels",
                            {
                              attrs: { multiple: "" },
                              model: {
                                value: _vm.panel,
                                callback: function ($$v) {
                                  _vm.panel = $$v
                                },
                                expression: "panel",
                              },
                            },
                            [
                              _c(
                                "v-expansion-panel",
                                [
                                  _c("v-expansion-panel-header", [
                                    _vm._v(" Backhual Loads "),
                                  ]),
                                  _c(
                                    "v-expansion-panel-content",
                                    {
                                      staticStyle: {
                                        "max-height": "400px",
                                        "overflow-y": "auto",
                                      },
                                    },
                                    [
                                      _c("v-data-table", {
                                        attrs: {
                                          headers: _vm.backhualHeaders,
                                          items: _vm.backhualShipments,
                                          loading: _vm.backhualLoading,
                                          "single-expand": "",
                                          "show-expand": "",
                                          "calculate-widths": "",
                                          "fixed-header": "",
                                          "hide-default-footer": "",
                                          "items-per-page": _vm.backhualPage,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "item.id",
                                              fn: function ({ item }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        text: "",
                                                        small: "",
                                                        target: "_blank",
                                                        to: {
                                                          name: "Load Detail",
                                                          params: {
                                                            id: item.id,
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.id) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.shipper",
                                              fn: function ({ item }) {
                                                return [
                                                  item.shipper !== null
                                                    ? _c("p", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.shipper.city
                                                            ) +
                                                            ", " +
                                                            _vm._s(
                                                              item.shipper
                                                                .state_province_region
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("br"),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "subtitle",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.pickupDate
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.consignee",
                                              fn: function ({ item }) {
                                                return [
                                                  item.consignee !== null
                                                    ? _c("p", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.consignee
                                                                .city
                                                            ) +
                                                            ", " +
                                                            _vm._s(
                                                              item.consignee
                                                                .state_province_region
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("br"),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "subtitle",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.deliveryDate
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.loadStatus",
                                              fn: function ({ item }) {
                                                return [
                                                  _c(
                                                    "load-status-edit-button",
                                                    {
                                                      attrs: {
                                                        "shipment-id": item.id,
                                                        "load-status":
                                                          item.loadStatus,
                                                        "parent-object": item,
                                                        "is-archived":
                                                          item.archived,
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.setLoadStatus,
                                                      },
                                                    }
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "expanded-item",
                                              fn: function ({ headers, item }) {
                                                return [
                                                  _c(
                                                    "td",
                                                    {
                                                      attrs: {
                                                        colspan: headers.length,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                                md: "2",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "text-h4 mb-1",
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Agent"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c("p", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.agentName
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                                md: "2",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "text-h4 mb-1",
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Equipment Type"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c("p", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.equipmentType
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                                md: "2",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "text-h4 mb-1",
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Customer Ref."
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c("p", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.customerReferenceId
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                                md: "2",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "text-h4 mb-1",
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Carrier Buy"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c("p", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.carrierStartBuy
                                                                    ) +
                                                                    " - " +
                                                                    _vm._s(
                                                                      item.carrierMaxBuy
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                                md: "2",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "text-h4 mb-1",
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Customer Pay"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c("p", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.customerPrice
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                                md: "2",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "text-h4 mb-1",
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Miles"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.miles
                                                                  )
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                                md: "2",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "text-h4 mb-1",
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Height"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c("p", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.loadHeight
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                                md: "2",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "text-h4 mb-1",
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Weight"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c("p", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.loadWeight
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                                md: "2",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "text-h4 mb-1",
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Length"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c("p", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.loadLength
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                                md: "2",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "text-h4 mb-1",
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Width"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c("p", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.loadWidth
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "8",
                                                                md: "2",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "text-h4 mb-1",
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Commodity"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c("p", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.commodity
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3878288756
                                        ),
                                      }),
                                      _c("v-page", {
                                        staticClass: "pt-3 pr-3",
                                        attrs: {
                                          "total-row": _vm.backhualTotalRecord,
                                          "page-size-menu": [10, 20, 50, 100],
                                        },
                                        on: {
                                          "page-change":
                                            _vm.backhualDataFromAPI,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-expansion-panel",
                                [
                                  _c("v-expansion-panel-header", [
                                    _vm._v(" Matching Loads "),
                                  ]),
                                  _c(
                                    "v-expansion-panel-content",
                                    {
                                      staticStyle: {
                                        "max-height": "400px",
                                        "overflow-y": "auto",
                                      },
                                    },
                                    [
                                      _c("v-data-table", {
                                        attrs: {
                                          headers: _vm.matchingHeaders,
                                          items: _vm.matchingShipments,
                                          loading: _vm.matchingLoading,
                                          "single-expand": "",
                                          "show-expand": "",
                                          "calculate-widths": "",
                                          "fixed-header": "",
                                          "hide-default-footer": "",
                                          "items-per-page": _vm.backhualPage,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "item.id",
                                              fn: function ({ item }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        text: "",
                                                        small: "",
                                                        target: "_blank",
                                                        to: {
                                                          name: "Load Detail",
                                                          params: {
                                                            id: item.id,
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.id) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.shipper",
                                              fn: function ({ item }) {
                                                return [
                                                  item.shipper !== null
                                                    ? _c("p", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.shipper.city
                                                            ) +
                                                            ", " +
                                                            _vm._s(
                                                              item.shipper
                                                                .state_province_region
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("br"),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "subtitle",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.pickupDate
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.consignee",
                                              fn: function ({ item }) {
                                                return [
                                                  item.consignee !== null
                                                    ? _c("p", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.consignee
                                                                .city
                                                            ) +
                                                            ", " +
                                                            _vm._s(
                                                              item.consignee
                                                                .state_province_region
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("br"),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "subtitle",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.deliveryDate
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item.loadStatus",
                                              fn: function ({ item }) {
                                                return [
                                                  _c(
                                                    "load-status-edit-button",
                                                    {
                                                      attrs: {
                                                        "shipment-id": item.id,
                                                        "load-status":
                                                          item.loadStatus,
                                                        "parent-object": item,
                                                        "is-archived":
                                                          item.archived,
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.setLoadStatus,
                                                      },
                                                    }
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "expanded-item",
                                              fn: function ({ headers, item }) {
                                                return [
                                                  _c(
                                                    "td",
                                                    {
                                                      attrs: {
                                                        colspan: headers.length,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                                md: "2",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "text-h4 mb-1",
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Agent"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c("p", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.agentName
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                                md: "2",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "text-h4 mb-1",
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Equipment Type"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c("p", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.equipmentType
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                                md: "2",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "text-h4 mb-1",
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Customer Ref."
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c("p", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.customerReferenceId
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                                md: "2",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "text-h4 mb-1",
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Carrier Buy"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c("p", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.carrierStartBuy
                                                                    ) +
                                                                    " - " +
                                                                    _vm._s(
                                                                      item.carrierMaxBuy
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                                md: "2",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "text-h4 mb-1",
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Customer Pay"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c("p", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.customerPrice
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                                md: "2",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "text-h4 mb-1",
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Miles"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c("p", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.miles
                                                                  )
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                                md: "2",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "text-h4 mb-1",
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Height"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c("p", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.loadHeight
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                                md: "2",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "text-h4 mb-1",
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Weight"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c("p", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.loadWeight
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                                md: "2",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "text-h4 mb-1",
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Length"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c("p", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.loadLength
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "4",
                                                                md: "2",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "text-h4 mb-1",
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Width"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c("p", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.loadWidth
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "8",
                                                                md: "2",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "text-h4 mb-1",
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Commodity"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c("p", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.commodity
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3878288756
                                        ),
                                      }),
                                      _c("v-page", {
                                        staticClass: "pt-3 pr-3",
                                        attrs: {
                                          "total-row": _vm.matchingTotalRecord,
                                          "page-size-menu": [10, 20, 50, 100],
                                        },
                                        on: {
                                          "page-change":
                                            _vm.matchingDataFromAPI,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }