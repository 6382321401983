var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pt-1", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pt-0" },
            [
              _c(
                "v-container",
                {
                  staticClass: "pl-0 pr-0 pt-0 data-container",
                  staticStyle: { "overflow-y": "auto", "max-height": "550px" },
                  attrs: { id: "tblQuotes", fluid: "" },
                },
                [
                  _c("v-data-table", {
                    staticClass: "elevation-2 data-table",
                    attrs: {
                      id: "tblData",
                      headers: _vm.headers,
                      items: _vm.quotes,
                      "calculate-widths": "",
                      "hide-default-footer": "",
                      "fixed-header": "",
                      "items-per-page": _vm.pageSize,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.load",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  text: "",
                                  small: "",
                                  target: "_blank",
                                  to: {
                                    name: "Load Detail",
                                    params: { id: item.load.id },
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(item.load.id) + " ")]
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.shipper",
                        fn: function ({ item }) {
                          return [
                            item.shipper
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatLocation(
                                        item.shipper.city,
                                        item.shipper.state_province_region
                                      )
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "item.consignee",
                        fn: function ({ item }) {
                          return [
                            item.consignee
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatLocation(
                                        item.consignee.city,
                                        item.consignee.state_province_region
                                      )
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "item.name",
                        fn: function ({ item }) {
                          return [
                            item.carrierCompany
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "name-link",
                                    attrs: {
                                      to: {
                                        name: "Carrier Detail",
                                        params: { id: item.carrierCompany },
                                      },
                                      target: "_blank",
                                    },
                                  },
                                  [_c("span", [_vm._v(_vm._s(item.name))])]
                                )
                              : _c(
                                  "span",
                                  [
                                    _vm._v(_vm._s(item.name) + " "),
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: { "x-small": "", color: "red" },
                                      },
                                      [_vm._v("mdi-bus-alert")]
                                    ),
                                  ],
                                  1
                                ),
                          ]
                        },
                      },
                      {
                        key: "footer",
                        fn: function () {
                          return [
                            _c("v-page", {
                              staticClass: "pt-3 pr-3 pb-3",
                              attrs: {
                                "total-row": _vm.totalRecord,
                                "page-size-menu": [10, 20, 50, 100],
                              },
                              on: { "page-change": _vm.readDataFromAPI },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }