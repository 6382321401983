var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        {
          staticClass: "pb-0 pt-0",
          staticStyle: { "max-width": "1600px" },
          attrs: { fluid: "" },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "div",
                { staticClass: "pa-2 header-banner" },
                [
                  _c("v-row", [
                    _c("div", { staticClass: "col-md-9 d-flex align-center" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "white rounded-circle d-inline-block align-center text-center short-name",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "transition-swing font-weight-bold grey--text",
                              staticStyle: { "font-size": "48px !important" },
                            },
                            [_vm._v(" " + _vm._s(_vm.shortCompanyName) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "white--text ml-4 mt-4" },
                        [
                          _c(
                            "v-list-item-title",
                            {
                              staticClass: "mb-1",
                              staticStyle: { "font-size": "24px" },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.currentCarrier.name) + " "
                              ),
                            ]
                          ),
                          _c("div", { staticStyle: { "font-size": "18px" } }, [
                            _vm._v(
                              " MC " +
                                _vm._s(_vm.currentCarrier.mcNumber) +
                                " - DOT " +
                                _vm._s(_vm.currentCarrier.dotNumber) +
                                " "
                            ),
                          ]),
                          _c("div", { staticStyle: { "font-size": "18px" } }, [
                            _vm._v(" " + _vm._s(_vm.currentCarrier.city)),
                            _vm.currentCarrier.state
                              ? _c("span", [_vm._v(",")])
                              : _vm._e(),
                            _vm._v(
                              " " + _vm._s(_vm.currentCarrier.state) + " "
                            ),
                          ]),
                          _c("div", { staticClass: "carrier-indicators" }, [
                            _c(
                              "span",
                              { class: _vm.indicators.paymentMethods.class },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.indicators.paymentMethods.text) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c("v-switch", {
                            attrs: { color: "secondary" },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "white--text text-h4" },
                                      [
                                        _vm._v(
                                          "Carrier Status: " +
                                            _vm._s(
                                              _vm.currentStatus
                                                ? "Active"
                                                : "Inactive"
                                            )
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.currentStatus,
                              callback: function ($$v) {
                                _vm.currentStatus = $$v
                              },
                              expression: "currentStatus",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm.currentCarrier.carrierManager
                      ? _c(
                          "div",
                          {
                            staticClass: "col-md-3",
                            style: _vm.$vuetify.breakpoint.mdAndUp
                              ? ""
                              : "display:none;",
                          },
                          [
                            _c(
                              "v-list-item-title",
                              {
                                staticClass: "mb-1",
                                staticStyle: {
                                  "font-size": "24px",
                                  color: "#ffffff",
                                  "text-align": "right",
                                },
                              },
                              [
                                _c("b", [_vm._v("Manager:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.currentCarrier.carrierManager.name
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: { centered: "" },
                      model: {
                        value: _vm.currentTab,
                        callback: function ($$v) {
                          _vm.currentTab = $$v
                        },
                        expression: "currentTab",
                      },
                    },
                    [
                      _c("v-tab", { attrs: { href: "#tab-1" } }, [
                        _vm._v("Summary"),
                      ]),
                      _c("v-tab", { attrs: { href: "#tab-2" } }, [
                        _vm._v("Details"),
                      ]),
                      _c("v-tab", { attrs: { href: "#tab-8" } }, [
                        _vm._v("Matches"),
                      ]),
                      _c("v-tab", { attrs: { href: "#tab-9" } }, [
                        _vm._v("Quotes"),
                      ]),
                      _c("v-tab", { attrs: { href: "#tab-3" } }, [
                        _vm._v("Shipments"),
                      ]),
                      _c("v-tab", { attrs: { href: "#tab-10" } }, [
                        _vm._v("Payments"),
                      ]),
                      _c("v-tab", { attrs: { href: "#tab-11" } }, [
                        _vm._v("Payment Methods"),
                      ]),
                      _c("v-tab", { attrs: { href: "#tab-4" } }, [
                        _vm._v("Documents"),
                      ]),
                      _c("v-tab", { attrs: { href: "#tab-5" } }, [
                        _vm._v("Lanes"),
                      ]),
                      _c("v-tab", { attrs: { href: "#tab-6" } }, [
                        _vm._v("Equipment"),
                      ]),
                      _c("v-tab", { attrs: { href: "#tab-7" } }, [
                        _vm._v("Notes"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-progress-linear", {
                attrs: {
                  active: _vm.loadingIcon,
                  color: "amber",
                  indeterminate: "",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        {
          staticClass: "pt-0 px-0",
          staticStyle: { "max-width": "1600px" },
          attrs: { fluid: "" },
        },
        [
          _c(
            "v-tabs-items",
            {
              staticStyle: { background: "none" },
              model: {
                value: _vm.currentTab,
                callback: function ($$v) {
                  _vm.currentTab = $$v
                },
                expression: "currentTab",
              },
            },
            [
              _c(
                "v-tab-item",
                { attrs: { value: "tab-1" } },
                [_c("carrier-summary", { attrs: { "carrier-id": _vm.id } })],
                1
              ),
              _c(
                "v-tab-item",
                { attrs: { value: "tab-2" } },
                [_c("profile-detail", { attrs: { "carrier-id": _vm.id } })],
                1
              ),
              _c(
                "v-tab-item",
                { attrs: { value: "tab-3" } },
                [
                  _c("profile-shipment", {
                    ref: "shipmentTab",
                    attrs: { "carrier-id": _vm.id },
                  }),
                ],
                1
              ),
              _c(
                "v-tab-item",
                { attrs: { value: "tab-4" } },
                [_c("documents", { attrs: { "carrier-id": _vm.id } })],
                1
              ),
              _c(
                "v-tab-item",
                { attrs: { value: "tab-5" } },
                [_c("lanes", { attrs: { "carrier-id": _vm.id } })],
                1
              ),
              _c(
                "v-tab-item",
                { attrs: { value: "tab-6" } },
                [_c("equipment", { attrs: { "carrier-id": _vm.id } })],
                1
              ),
              _c(
                "v-tab-item",
                { attrs: { value: "tab-7" } },
                [_c("notes", { attrs: { "carrier-id": _vm.id } })],
                1
              ),
              _c(
                "v-tab-item",
                { attrs: { value: "tab-8" } },
                [
                  _c("match-loads", {
                    ref: "matchLoadTab",
                    attrs: { "carrier-id": _vm.id },
                  }),
                ],
                1
              ),
              _c(
                "v-tab-item",
                { attrs: { value: "tab-9" } },
                [
                  _c("quotes", {
                    ref: "quoteTab",
                    attrs: { "carrier-id": _vm.id },
                  }),
                ],
                1
              ),
              _c(
                "v-tab-item",
                { attrs: { value: "tab-10" } },
                [
                  _c("carrier-payments", {
                    ref: "paymentTab",
                    attrs: { "carrier-id": _vm.id },
                  }),
                ],
                1
              ),
              _c(
                "v-tab-item",
                { attrs: { value: "tab-11" } },
                [_c("payment-methods", { attrs: { "carrier-id": _vm.id } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }