var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pt-1", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pt-0", attrs: { cols: _vm.columnSize } },
            [
              _c(
                "v-container",
                {
                  staticClass: "pl-0 pr-0 pt-0 data-container",
                  staticStyle: { "overflow-y": "auto", "max-height": "550px" },
                  attrs: { id: "tblShipments", fluid: "" },
                },
                [
                  _c("load-tracking"),
                  _c("v-data-table", {
                    staticClass: "elevation-2 data-table",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.shipments,
                      "items-per-page": _vm.pageSize,
                      "calculate-widths": "",
                      "fixed-header": "",
                      "hide-default-footer": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.customerCompany",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "p",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.loadClick(item.id)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(item.customerCompany) + " ")]
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.id",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  to: {
                                    name: "Load Detail",
                                    params: { id: item.id },
                                  },
                                  small: "",
                                  target: "_blank",
                                  text: "",
                                },
                              },
                              [_vm._v(" " + _vm._s(item.id) + " ")]
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.shipper",
                        fn: function ({ item }) {
                          return [
                            item.shipper !== null
                              ? _c("p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatLocation(
                                          item.shipper.city,
                                          item.shipper.state_province_region
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c("br"),
                                  _c("span", { staticClass: "subtitle" }, [
                                    _vm._v(_vm._s(item.pickupDate)),
                                  ]),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "item.consignee",
                        fn: function ({ item }) {
                          return [
                            item.consignee !== null
                              ? _c("p", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatLocation(
                                          item.consignee.city,
                                          item.consignee.state_province_region
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c("br"),
                                  _c("span", { staticClass: "subtitle" }, [
                                    _vm._v(_vm._s(item.deliveryDate)),
                                  ]),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "item.loadStatus",
                        fn: function ({ item }) {
                          return [
                            _c("load-status-button", {
                              attrs: {
                                "agent-detail": _vm.agentDetail,
                                "load-status-items": _vm.loadStatusChoices,
                                "shipment-detail": item,
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: "item.margin",
                        fn: function ({ item }) {
                          return [
                            item.margin !== null
                              ? _c("p", [_vm._v(_vm._s(item.margin) + "%")])
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "item.carrierPrice",
                        fn: function ({ item }) {
                          return [
                            item.carrierPrice !== null
                              ? _c("p", [
                                  _vm._v("$" + _vm._s(item.carrierPrice)),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "item.customerPrice",
                        fn: function ({ item }) {
                          return [
                            item.customerPrice !== null
                              ? _c("p", [
                                  _vm._v(
                                    " $" + _vm._s(item.customerPrice) + " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "footer",
                        fn: function () {
                          return [
                            _c("v-page", {
                              staticClass: "pt-3 pr-3 pb-3",
                              attrs: {
                                "page-size-menu": [10, 20, 50, 100],
                                "total-row": _vm.totalRecord,
                              },
                              on: { "page-change": _vm.readDataFromAPI },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showDetail
            ? _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "mt-0",
                      staticStyle: { height: "730px", "overflow-y": "auto" },
                      attrs: { light: "" },
                    },
                    [
                      _c("shipment-info"),
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("tender-detail", {
                                    attrs: { "show-edit": false },
                                  }),
                                  _c(
                                    "v-container",
                                    {
                                      staticClass: "mt-2",
                                      staticStyle: {
                                        border: "1px solid #cccccc",
                                        "border-radius": "2px",
                                      },
                                    },
                                    [
                                      _c("shipper-info", {
                                        attrs: {
                                          "hgt-class": _vm.heightClass,
                                          "show-edit": false,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-container",
                                    {
                                      staticClass: "mt-2",
                                      staticStyle: {
                                        border: "1px solid #cccccc",
                                        "border-radius": "2px",
                                      },
                                    },
                                    [
                                      _c("consignee-info", {
                                        attrs: {
                                          "hgt-class": _vm.heightClass,
                                          "show-edit": false,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-container",
                                    {
                                      staticClass: "mt-2",
                                      staticStyle: {
                                        border: "1px solid #cccccc",
                                        "border-radius": "2px",
                                      },
                                    },
                                    [
                                      _c("commodity-dimensions", {
                                        attrs: {
                                          "hgt-class": _vm.heightClass,
                                          "show-edit": false,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-container",
                                    {
                                      staticClass: "mt-2",
                                      staticStyle: {
                                        border: "1px solid #cccccc",
                                        "border-radius": "2px",
                                      },
                                    },
                                    [
                                      _c("carrier-info", {
                                        attrs: {
                                          "hgt-class": _vm.heightClass,
                                          "show-edit": false,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }