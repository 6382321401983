import { render, staticRenderFns } from "./Shipment.vue?vue&type=template&id=117f8bcc&scoped=true&"
import script from "./Shipment.vue?vue&type=script&lang=js&"
export * from "./Shipment.vue?vue&type=script&lang=js&"
import style0 from "./Shipment.vue?vue&type=style&index=0&id=117f8bcc&scoped=true&lang=css&"
import style1 from "./Shipment.vue?vue&type=style&index=1&id=117f8bcc&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "117f8bcc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/cuzox/projects/exo/Exo-TMS-UI/v1/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('117f8bcc')) {
      api.createRecord('117f8bcc', component.options)
    } else {
      api.reload('117f8bcc', component.options)
    }
    module.hot.accept("./Shipment.vue?vue&type=template&id=117f8bcc&scoped=true&", function () {
      api.rerender('117f8bcc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/carrier-profile/Shipment.vue"
export default component.exports