var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "3" } },
            [
              _c(
                "v-card",
                { staticClass: "mt-0", attrs: { "min-height": "512" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "blue--text text--lighten-2" },
                    [_vm._v(" Stats ")]
                  ),
                  _c("v-card-text", [
                    _c("p", { staticClass: "col-md-12 mb-0 pb-0 pl-0" }, [
                      _c("b", [_vm._v("Total Shipments:")]),
                      _vm._v(
                        " " + _vm._s(_vm.carrierStats.totalShipment) + " "
                      ),
                    ]),
                    _c("p", { staticClass: "col-md-12 mb-0 pb-0 pl-0" }, [
                      _c("b", [_vm._v("Margin %:")]),
                      _vm._v(
                        " " + _vm._s(_vm.carrierStats.averageMargin) + " "
                      ),
                    ]),
                    _c("p", { staticClass: "col-md-12 mb-0 pb-0 pl-0" }, [
                      _c("b", [_vm._v("Send Offers:")]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.currentCarrier.sendOffers ? "Yes" : "No") +
                          " "
                      ),
                    ]),
                    _c("p", { staticClass: "col-md-12 mb-0 pb-0 pl-0" }, [
                      _c("b", [_vm._v("Days Between Emails:")]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.currentCarrier.daysBetweenEmails) +
                          " Day(s) "
                      ),
                    ]),
                    _c("p", { staticClass: "col-md-12 mb-0 pb-0 pl-0" }, [
                      _c("b", [_vm._v("Insurance Status:")]),
                      _vm._v(
                        " " + _vm._s(_vm.currentCarrier.insuranceActive) + " "
                      ),
                    ]),
                    _c("p", { staticClass: "col-md-12 mb-0 pb-0 pl-0" }, [
                      _c("b", [_vm._v("Approved:")]),
                      _vm._v(" " + _vm._s(_vm.currentCarrier.approved) + " "),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "5" } },
            [
              _c(
                "v-card",
                { staticClass: "mt-0", attrs: { "min-height": "512" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "blue--text text--lighten-2" },
                    [_vm._v(" Related Customers ")]
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          model: {
                            value: _vm.valid,
                            callback: function ($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid",
                          },
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "5" } },
                                [_c("customer-auto-complete")],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "5" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      label: "Network Type",
                                      items: _vm.networkOptions,
                                      rules: [_vm.rules.required],
                                    },
                                    model: {
                                      value: _vm.network,
                                      callback: function ($$v) {
                                        _vm.network = $$v
                                      },
                                      expression: "network",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "while--text blue text--lighten-2 font-weight-bold mt-2 py-0",
                                      attrs: { small: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addToCarrier()
                                        },
                                      },
                                    },
                                    [_vm._v(" Add ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pt-0", attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                staticClass: "pt-0",
                                attrs: {
                                  "append-icon": "mdi-magnify",
                                  label: "Search",
                                  "single-line": "",
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.search,
                                  callback: function ($$v) {
                                    _vm.search = $$v
                                  },
                                  expression: "search",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "min-height": "335px",
                                  "overflow-y": "auto",
                                },
                              },
                              [
                                _c("v-data-table", {
                                  staticClass: "elevation-1 mx-1",
                                  attrs: {
                                    headers: _vm.headers,
                                    items: _vm.currentCarrier.customerCompanies,
                                    "calculate-widths": "",
                                    "fixed-header": "",
                                    "items-per-page": 5,
                                    "footer-props": {
                                      "items-per-page-options": [5],
                                    },
                                    search: _vm.search,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "item.network",
                                      fn: function ({ item }) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.network.length
                                                  ? item.network[0].type_text
                                                  : ""
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "item.id",
                                      fn: function ({ item }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "while--text red text--lighten-2 font-weight-bold",
                                              attrs: { small: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeFromCarrier(
                                                    item.id
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" Remove ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4" } },
            [
              _c(
                "v-card",
                { staticClass: "mt-0", attrs: { "min-height": "512" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "blue--text text--lighten-2" },
                    [
                      _vm._v(" Shipments "),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "float-right blue--text text--lighten-2",
                          attrs: { small: "", text: "" },
                          on: { click: _vm.viewAllShipments },
                        },
                        [_vm._v(" View All ")]
                      ),
                    ],
                    1
                  ),
                  _c("v-card-text", { staticClass: "pb-0" }, [
                    _vm.recentShipments
                      ? _c(
                          "div",
                          [
                            _vm._l(_vm.recentShipments, function (item, key) {
                              return [
                                _c(
                                  "v-row",
                                  {
                                    key: key,
                                    style:
                                      key !== 4
                                        ? "border-bottom: 1px solid #E0E0E0"
                                        : "",
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "col-md-12 mb-0 pb-0 font-weight-bold",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.customerCompany) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    item.shipper
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              "col-md-4 mb-0 pb-1 text-left",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  `${item.shipper.city}${
                                                    item.shipper
                                                      .state_province_region
                                                      ? ", " +
                                                        item.shipper
                                                          .state_province_region
                                                      : ""
                                                  }`
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "min-width": "160px",
                                            },
                                          },
                                          [_vm._v("-")]
                                        ),
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass:
                                          "col-md-2 mb-0 pb-1 text-left",
                                      },
                                      [_vm._v(" mdi-arrow-right-bold ")]
                                    ),
                                    item.consignee
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              "col-md-4 mb-0 pb-1 text-left",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  `${item.consignee.city}${
                                                    item.consignee
                                                      .state_province_region
                                                      ? ", " +
                                                        item.consignee
                                                          .state_province_region
                                                      : ""
                                                  }`
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "min-width": "160px",
                                            },
                                          },
                                          [_vm._v("-")]
                                        ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "col-md-2 mb-0 pb-1 text-left",
                                      },
                                      [
                                        item.carrierPrice
                                          ? _c("span", [_vm._v("$")])
                                          : _vm._e(),
                                        _vm._v(_vm._s(item.carrierPrice) + " "),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }