var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-container",
            [
              _c("v-row", [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-md-12 blue--text text--lighten-2 font-size-14",
                  },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "pl-3 pr-1 blue--text text--lighten-2",
                        attrs: { medium: "" },
                      },
                      [_vm._v(" mdi-highway ")]
                    ),
                    _vm._v(" Equipment "),
                  ],
                  1
                ),
              ]),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "2", sm: "3" } },
                    [
                      _c("v-checkbox", {
                        attrs: { label: "Wrecker Service" },
                        model: {
                          value:
                            _vm.currentCarrier.equipmentTypes.wreckerService,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.currentCarrier.equipmentTypes,
                              "wreckerService",
                              $$v
                            )
                          },
                          expression:
                            "currentCarrier.equipmentTypes.wreckerService",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "2", sm: "3" } },
                    [
                      _c("v-checkbox", {
                        attrs: { label: "RGN" },
                        model: {
                          value: _vm.currentCarrier.equipmentTypes.rgn,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.currentCarrier.equipmentTypes,
                              "rgn",
                              $$v
                            )
                          },
                          expression: "currentCarrier.equipmentTypes.rgn",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "2", sm: "3" } },
                    [
                      _c("v-checkbox", {
                        attrs: { label: "VAN" },
                        model: {
                          value: _vm.currentCarrier.equipmentTypes.van,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.currentCarrier.equipmentTypes,
                              "van",
                              $$v
                            )
                          },
                          expression: "currentCarrier.equipmentTypes.van",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "2", sm: "3" } },
                    [
                      _c("v-checkbox", {
                        attrs: { label: "Power Only" },
                        model: {
                          value: _vm.currentCarrier.equipmentTypes.powerOnly,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.currentCarrier.equipmentTypes,
                              "powerOnly",
                              $$v
                            )
                          },
                          expression: "currentCarrier.equipmentTypes.powerOnly",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "2", sm: "3" } },
                    [
                      _c("v-checkbox", {
                        attrs: { label: "Step Deck" },
                        model: {
                          value: _vm.currentCarrier.equipmentTypes.stepDeck,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.currentCarrier.equipmentTypes,
                              "stepDeck",
                              $$v
                            )
                          },
                          expression: "currentCarrier.equipmentTypes.stepDeck",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "2", sm: "3" } },
                    [
                      _c("v-checkbox", {
                        attrs: { label: "Flatbed" },
                        model: {
                          value: _vm.currentCarrier.equipmentTypes.flatbed,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.currentCarrier.equipmentTypes,
                              "flatbed",
                              $$v
                            )
                          },
                          expression: "currentCarrier.equipmentTypes.flatbed",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "2", sm: "3" } },
                    [
                      _c("v-checkbox", {
                        attrs: { label: "Reefer" },
                        model: {
                          value: _vm.currentCarrier.equipmentTypes.reefer,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.currentCarrier.equipmentTypes,
                              "reefer",
                              $$v
                            )
                          },
                          expression: "currentCarrier.equipmentTypes.reefer",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "2", sm: "3" } },
                    [
                      _c("v-checkbox", {
                        attrs: { label: "Hotshot" },
                        model: {
                          value: _vm.currentCarrier.equipmentTypes.hotshot,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.currentCarrier.equipmentTypes,
                              "hotshot",
                              $$v
                            )
                          },
                          expression: "currentCarrier.equipmentTypes.hotshot",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "2", sm: "3" } },
                    [
                      _c("v-checkbox", {
                        attrs: { label: "Specialized" },
                        model: {
                          value: _vm.currentCarrier.equipmentTypes.specialized,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.currentCarrier.equipmentTypes,
                              "specialized",
                              $$v
                            )
                          },
                          expression:
                            "currentCarrier.equipmentTypes.specialized",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "offset-md-10",
                      attrs: { cols: "12", md: "2" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "blue white--text font-weight-bold",
                          on: { click: _vm.updateEquipment },
                        },
                        [_vm._v(" Update Equipment ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }